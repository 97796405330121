import React from "react";
import { Link } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import {
    setCourseFeesData,
    setLogInDetails, setMedicalsData,
    setMinAmountAllowData,
    setOtherFeesData,
    setPaymentHistoryData,
    setScholarshipData,
    setWalletData
} from "../../actions/setpersonaldetail";
import { connect } from "react-redux";
import Logo from "../images/logo.png";

const Header = ({
                    setOnLogInDetails,
                    setOnCourseFeeData,
                    setOnWalletData,
                    setOnMinAmountAllowData,
                    setOnPaymentHistoryData,
                    setOnScholarshipData,
                    setOnOtherFeesData,
                    setOnMedicalsData
                }) => {
    const logout = () => {
        const loginDetails = {
            message: '', student_name: '', student_id: '', student_email: '', course_id: '',
            app_type: '', student_level: '', student_semester: '', isPaymentLoggedIn: false
        }
        setOnLogInDetails(loginDetails);
        setOnCourseFeeData("");
        setOnWalletData("");
        setOnMinAmountAllowData("");
        setOnPaymentHistoryData("");
        setOnScholarshipData("");
        setOnOtherFeesData("");
        setOnMedicalsData([])
        setTimeout(() => {
            window.location.href = '/payment/#/login';
        }, 1000);
    };

    const SESSION_IDLE_MINUTES = 5;
    const handleOnIdle = (event) => {
        console.log('last active', getLastActiveTime())
        logout();
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return (
        <header id="header"
                className="navbar navbar-expand-lg navbar-end navbar-light navbar-absolute-top navbar-show-hide"
                data-hs-header-options='{
                    "fixMoment": 0,
                    "fixEffect": "slide"
                }'>
            <div className="container">
                <nav className="js-mega-menu navbar-nav-wrap">
                    <Link className="navbar-brand" to="/">
                        <img className="navbar-brand-logo" src={Logo}
                             alt="Baze University Abuja"/>
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-default">
                            <i className="bi-list"/>
                        </span>
                        <span className="navbar-toggler-toggled">
                            <i className="bi-x"/>
                        </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className="navbar-absolute-top-scroller">
                            <ul className="navbar-nav nav-pills">
                                <li className="nav-item">
                                    <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/dashboard">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/pay-now">
                                        Pay Now
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/history">
                                        Payment History
                                    </Link>
                                </li>

                                <li className="nav-item ms-lg-auto">
                                    <button className="btn btn-ghost-dark me-2 me-lg-0"
                                            onClick={logout}>Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLogInDetails: (p) => {
            dispatch(setLogInDetails(p));
        },
        setOnWalletData: (p) => {
            dispatch(setWalletData(p));
        },
        setOnCourseFeeData: (p) => {
            dispatch(setCourseFeesData(p));
        },
        setOnMinAmountAllowData: (p) => {
            dispatch(setMinAmountAllowData(p));
        },
        setOnPaymentHistoryData: (p) => {
            dispatch(setPaymentHistoryData(p));
        },
        setOnScholarshipData: (p) => {
            dispatch(setScholarshipData(p));
        },
        setOnOtherFeesData: (p) => {
            dispatch(setOtherFeesData(p));
        },
        setOnMedicalsData: (p) => {
            dispatch(setMedicalsData(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(Header);
